import { providers } from "ethers";
import connect from "./index";
// import WalletConnectProvider from "@walletconnect/web3-provider";

//import Web3 from "web3";
import { provider, setProvider } from "../../walletConnect/provider";

//var web3;
// fixed provider syntax

function updateChainId(chainId) {
  localStorage.setItem("currentChain", `${chainId}`);
}

const connectWalletConnect = async () => {
  console.log(provider);
  try {
    const { state } = connect();
    console.log("state as", state);

    //  Enable session (triggers QR Code modal)
    await provider.enable();

    const web3Provider = new providers.Web3Provider(provider);

    // web3 = new Web3(provider);

    const signer = await web3Provider.getSigner();
    const address = await signer.getAddress();

    state.status = true;
    state.address = address;
    state.chainId = await provider.request({ method: "eth_chainId" });
    console.log(state.chainId);
    updateChainId(state.chainId);

    provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      console.log("disconnected");
      state.status = false;
      state.address = "";
      localStorage.removeItem("userState");
      localStorage.removeItem("currentChain");
    });

    provider.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        state.address = accounts[0];
      }
    });

    provider.on("chainChanged", (chainId) => {
      console.log(chainId);
      state.chainId = chainId;
      updateChainId(state.chainId);
    });
  } catch (error) {
    console.log(error);
    setProvider();
  }
};
// export async function approvetk() {
//   console.log("hit");

//   //   let connctionQuery = connectWC();
//   let connctionQuery = true;
//   if (connctionQuery) {
//     // const web3 = new Web3(Web3.givenProvider || "ws://localhost:5500");

//     // Get the contract ABI from compiled smart contract json
//     const erc20TokenContractAbi = [
//       {
//         constant: true,
//         inputs: [],
//         name: "name",
//         outputs: [
//           {
//             name: "",
//             type: "string",
//           },
//         ],
//         payable: false,
//         stateMutability: "view",
//         type: "function",
//       },
//       {
//         constant: false,
//         inputs: [
//           {
//             name: "spender",
//             type: "address",
//           },
//           {
//             name: "tokens",
//             type: "uint256",
//           },
//         ],
//         name: "approve",
//         outputs: [
//           {
//             name: "success",
//             type: "bool",
//           },
//         ],
//         payable: false,
//         stateMutability: "nonpayable",
//         type: "function",
//       },
//       {
//         constant: true,
//         inputs: [],
//         name: "totalSupply",
//         outputs: [
//           {
//             name: "",
//             type: "uint256",
//           },
//         ],
//         payable: false,
//         stateMutability: "view",
//         type: "function",
//       },
//       {
//         constant: false,
//         inputs: [
//           {
//             name: "from",
//             type: "address",
//           },
//           {
//             name: "to",
//             type: "address",
//           },
//           {
//             name: "tokens",
//             type: "uint256",
//           },
//         ],
//         name: "transferFrom",
//         outputs: [
//           {
//             name: "success",
//             type: "bool",
//           },
//         ],
//         payable: false,
//         stateMutability: "nonpayable",
//         type: "function",
//       },
//       {
//         constant: true,
//         inputs: [],
//         name: "decimals",
//         outputs: [
//           {
//             name: "",
//             type: "uint8",
//           },
//         ],
//         payable: false,
//         stateMutability: "view",
//         type: "function",
//       },
//       {
//         constant: false,
//         inputs: [
//           {
//             name: "amount",
//             type: "uint256",
//           },
//         ],
//         name: "withdrawEther",
//         outputs: [],
//         payable: false,
//         stateMutability: "nonpayable",
//         type: "function",
//       },
//       {
//         constant: true,
//         inputs: [],
//         name: "_totalSupply",
//         outputs: [
//           {
//             name: "",
//             type: "uint256",
//           },
//         ],
//         payable: false,
//         stateMutability: "view",
//         type: "function",
//       },
//       {
//         constant: true,
//         inputs: [
//           {
//             name: "tokenOwner",
//             type: "address",
//           },
//         ],
//         name: "balanceOf",
//         outputs: [
//           {
//             name: "balance",
//             type: "uint256",
//           },
//         ],
//         payable: false,
//         stateMutability: "view",
//         type: "function",
//       },
//       {
//         constant: false,
//         inputs: [],
//         name: "acceptOwnership",
//         outputs: [],
//         payable: false,
//         stateMutability: "nonpayable",
//         type: "function",
//       },
//       {
//         constant: true,
//         inputs: [],
//         name: "owner",
//         outputs: [
//           {
//             name: "",
//             type: "address",
//           },
//         ],
//         payable: false,
//         stateMutability: "view",
//         type: "function",
//       },
//       {
//         constant: true,
//         inputs: [],
//         name: "symbol",
//         outputs: [
//           {
//             name: "",
//             type: "string",
//           },
//         ],
//         payable: false,
//         stateMutability: "view",
//         type: "function",
//       },
//       {
//         constant: true,
//         inputs: [
//           {
//             name: "a",
//             type: "uint256",
//           },
//           {
//             name: "b",
//             type: "uint256",
//           },
//         ],
//         name: "safeSub",
//         outputs: [
//           {
//             name: "c",
//             type: "uint256",
//           },
//         ],
//         payable: false,
//         stateMutability: "pure",
//         type: "function",
//       },
//       {
//         constant: false,
//         inputs: [
//           {
//             name: "to",
//             type: "address",
//           },
//           {
//             name: "tokens",
//             type: "uint256",
//           },
//         ],
//         name: "transfer",
//         outputs: [
//           {
//             name: "success",
//             type: "bool",
//           },
//         ],
//         payable: false,
//         stateMutability: "nonpayable",
//         type: "function",
//       },
//       {
//         constant: true,
//         inputs: [
//           {
//             name: "a",
//             type: "uint256",
//           },
//           {
//             name: "b",
//             type: "uint256",
//           },
//         ],
//         name: "safeDiv",
//         outputs: [
//           {
//             name: "c",
//             type: "uint256",
//           },
//         ],
//         payable: false,
//         stateMutability: "pure",
//         type: "function",
//       },
//       {
//         constant: false,
//         inputs: [
//           {
//             name: "spender",
//             type: "address",
//           },
//           {
//             name: "tokens",
//             type: "uint256",
//           },
//           {
//             name: "data",
//             type: "bytes",
//           },
//         ],
//         name: "approveAndCall",
//         outputs: [
//           {
//             name: "success",
//             type: "bool",
//           },
//         ],
//         payable: false,
//         stateMutability: "nonpayable",
//         type: "function",
//       },
//       {
//         constant: true,
//         inputs: [
//           {
//             name: "a",
//             type: "uint256",
//           },
//           {
//             name: "b",
//             type: "uint256",
//           },
//         ],
//         name: "safeMul",
//         outputs: [
//           {
//             name: "c",
//             type: "uint256",
//           },
//         ],
//         payable: false,
//         stateMutability: "pure",
//         type: "function",
//       },
//       {
//         constant: true,
//         inputs: [],
//         name: "newOwner",
//         outputs: [
//           {
//             name: "",
//             type: "address",
//           },
//         ],
//         payable: false,
//         stateMutability: "view",
//         type: "function",
//       },
//       {
//         constant: false,
//         inputs: [
//           {
//             name: "tokenAddress",
//             type: "address",
//           },
//           {
//             name: "tokens",
//             type: "uint256",
//           },
//         ],
//         name: "transferAnyERC20Token",
//         outputs: [
//           {
//             name: "success",
//             type: "bool",
//           },
//         ],
//         payable: false,
//         stateMutability: "nonpayable",
//         type: "function",
//       },
//       {
//         constant: true,
//         inputs: [
//           {
//             name: "tokenOwner",
//             type: "address",
//           },
//           {
//             name: "spender",
//             type: "address",
//           },
//         ],
//         name: "allowance",
//         outputs: [
//           {
//             name: "remaining",
//             type: "uint256",
//           },
//         ],
//         payable: false,
//         stateMutability: "view",
//         type: "function",
//       },
//       {
//         constant: true,
//         inputs: [
//           {
//             name: "a",
//             type: "uint256",
//           },
//           {
//             name: "b",
//             type: "uint256",
//           },
//         ],
//         name: "safeAdd",
//         outputs: [
//           {
//             name: "c",
//             type: "uint256",
//           },
//         ],
//         payable: false,
//         stateMutability: "pure",
//         type: "function",
//       },
//       {
//         constant: false,
//         inputs: [
//           {
//             name: "_newOwner",
//             type: "address",
//           },
//         ],
//         name: "transferOwnership",
//         outputs: [],
//         payable: false,
//         stateMutability: "nonpayable",
//         type: "function",
//       },
//       {
//         inputs: [],
//         payable: false,
//         stateMutability: "nonpayable",
//         type: "constructor",
//       },
//       {
//         payable: true,
//         stateMutability: "payable",
//         type: "fallback",
//       },
//       {
//         anonymous: false,
//         inputs: [
//           {
//             indexed: true,
//             name: "_from",
//             type: "address",
//           },
//           {
//             indexed: true,
//             name: "_to",
//             type: "address",
//           },
//         ],
//         name: "OwnershipTransferred",
//         type: "event",
//       },
//       {
//         anonymous: false,
//         inputs: [
//           {
//             indexed: true,
//             name: "from",
//             type: "address",
//           },
//           {
//             indexed: true,
//             name: "to",
//             type: "address",
//           },
//           {
//             indexed: false,
//             name: "tokens",
//             type: "uint256",
//           },
//         ],
//         name: "Transfer",
//         type: "event",
//       },
//       {
//         anonymous: false,
//         inputs: [
//           {
//             indexed: true,
//             name: "tokenOwner",
//             type: "address",
//           },
//           {
//             indexed: true,
//             name: "spender",
//             type: "address",
//           },
//           {
//             indexed: false,
//             name: "tokens",
//             type: "uint256",
//           },
//         ],
//         name: "Approval",
//         type: "event",
//       },
//     ];

//     // Create contract object
//     const tokenContractAddress = "0xc778417E063141139Fce010982780140Aa0cD5Ab";

//     // Instantiate contract
//     const tokenContract = new web3.eth.Contract(
//       erc20TokenContractAbi,
//       tokenContractAddress
//     );
//     const toAddress = "0xa49D8140B4D10b5215558ffe1cC2b4745CF6d51D";

//     // Calculate contract compatible value for approve with proper decimal points using BigNumber
//     const tokenDecimals = web3.utils.toBN(18);
//     const tokenAmountToApprove = web3.utils.toBN(9990000000000000000);
//     const calculatedApproveValue = web3.utils.toHex(
//       tokenAmountToApprove.mul(web3.utils.toBN(10).pow(tokenDecimals))
//     );

//     // const Contract = ('0xContractAddress');
//     // const spenderAdr = ('0xSpenderAddress');
//     // const amount = ('AmountTokensNumber')

//     // async function approvebutton(Contract, spenderAdr) {
//     //     Contract.methods.approve(spenderAddr, amount).send({
//     //         from: ownerAddr
//     //     })
//     // }

//     web3.eth.getAccounts().then((e) => {
//       console.log(e);
//     });

//     // Get user account wallet address first
//     web3.eth.getAccounts().then((accounts) => {
//       // Send ERC20 transaction with web3
//       tokenContract.methods
//         .approve(toAddress, calculatedApproveValue)
//         .send({
//           from: accounts[0],
//         })
//         .once("transactionHash", (hash) => {
//           console.log(hash);
//         })
//         .once("receipt", (receipt) => {
//           console.log(receipt);
//         });
//     });
//   } else {
//     alert("please connect your wallet");
//   }
// }

export default connectWalletConnect;
