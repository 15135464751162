<template>
  <nav-modal />
  <div class="bg-white pt-4">
    <main>
      <status-container @connectStateChange="incrementAppState()" />
      <router-view :key="appState.render" />
    </main>
  </div>
</template>

<script>
import navModal from "./components/navModal.vue";
import StatusContainer from "./components/StatusContainer.vue";
import connect from "./composables/connect/index";
import { onMounted, reactive } from "vue";

export default {
  name: "App",
  components: {
    StatusContainer,
    navModal,
  },
  setup: () => {
    let appState = reactive({ render: 0 });
    function incrementAppState() {
      appState.render++;
    }
    const { state, autoConnect } = connect();
    onMounted(async () => {
      console.log(state);
      await autoConnect();
    });
    return {
      appState,
      incrementAppState,
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@300&display=swap");
#app {
  font-family: "Sora", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
