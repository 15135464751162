<template>
  <div class="ml-3 inline-flex rounded-md shadow">
    <a
      target="_blank"
      href="https://github.com/web2033/vite-vue3-tailwind-starter"
      class="inline-flex items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium leading-6 text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:outline-none"
      >Learn more</a
    >
  </div>
</template>
