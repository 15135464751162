import WalletConnectProvider from "@walletconnect/web3-provider";

var provider = new WalletConnectProvider({
  infuraId: "46795cf44ffc462885b7bd738b913e38",
});

function setProvider() {
  provider = new WalletConnectProvider({
    infuraId: "46795cf44ffc462885b7bd738b913e38",
  });
}

export { provider, setProvider };
