<template>
  <div>
    <div class="container mx-auto px-5 py-12">
      <div class="my-20 flex w-full flex-col text-center">
        <h2
          class="title-font mb-1 text-xs font-medium tracking-widest text-blue-500"
        >
          The Future is here!
        </h2>
        <h1
          class="title-font mb-4 text-3xl font-medium text-gray-900 sm:text-3xl"
        >
          Welcome to Your Web3 Dapp
        </h1>
        <p class="mx-auto text-base leading-relaxed lg:w-2/3">
          Multiple iOS and Android wallets support the WalletConnect protocol.
          Interaction between mobile apps and mobile browsers are supported via
          mobile deep linking.
        </p>

        <div class="inline-flex w-full place-content-center rounded-md pt-6">
          <button
            v-if="!state.status"
            @click="connectUserWallet"
            class="m-2 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out hover:bg-blue-900 focus:outline-none"
          >
            Connect Wallet
          </button>
          <button
            v-if="state.status"
            @click="disconnectUser"
            class="hover:-trangreen-y-1 m-2 inline-flex items-center justify-center rounded-md border border-transparent bg-green-100 px-5 py-3 text-center text-xs font-medium leading-6 text-green-800 transition delay-150 duration-300 ease-in-out hover:scale-110 hover:bg-red-200 hover:text-slate-900 focus:outline-none"
          >
            Disconnect :
            {{ state.address }}
          </button>
          <button
            v-if="dev"
            @click="TestMessaging"
            class="m-2 inline-flex items-center justify-center rounded-md border border-transparent bg-gray-800 px-5 py-3 text-center text-base font-medium leading-6 text-white transition duration-150 ease-in-out hover:bg-gray-900 focus:outline-none"
          >
            TestMessaging
          </button>
        </div>
      </div>
    </div>
    <!-- <div
      class="item-center mx-auto max-w-screen-xl px-4 pb-12 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8"
    >
      <div class="place-content-center">
        <h2
          class="text-3xl leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
        >
          Welcome to Your Web3 Dapp
        </h2>
        <br />
        <span class="text-indigo-600" v-if="state.status"
          ><p>Address: {{ state.address }}</p>
          <p>ChainId: {{ state.chainId }}</p>
        </span>
      </div>

      <div class="mt-8 flex lg:mt-0 lg:flex-shrink-0">
        <div class="inline-flex w-full place-content-center rounded-md">
          <button
            v-if="!state.status"
            @click="connectUserWallet"
            class="m-2 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out hover:bg-blue-900 focus:outline-none"
          >
            Connect Wallet
          </button>
          <button
            v-if="state.status"
            @click="disconnectUser"
            class="m-2 inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-5 py-3 text-center text-base font-medium leading-6 text-white transition duration-150 ease-in-out hover:bg-red-900 focus:outline-none"
          >
            disconnect
          </button>
          <button
            v-if="dev"
            @click="TestMessaging"
            class="m-2 inline-flex items-center justify-center rounded-md border border-transparent bg-gray-800 px-5 py-3 text-center text-base font-medium leading-6 text-white transition duration-150 ease-in-out hover:bg-gray-900 focus:outline-none"
          >
            TestMessaging
          </button>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import connect from "../composables/connect/index";
import { addTxDoc } from "../composables/dash";

export default {
  name: "StatusContainer",

  setup: (props, ctx) => {
    const { connectWalletConnect, disconnectWallet, state } = connect();
    const connectUserWallet = async () => {
      await connectWalletConnect().then(() => ctx.emit("connectStateChange"));
      console.log(props);
    };

    const disconnectUser = async () => {
      await disconnectWallet();
    };
    const TestMessaging = () => {
      addTxDoc();
    };
    const dev = !true;

    return {
      connectUserWallet,
      disconnectUser,
      state,
      dev,
      TestMessaging,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
