import HomePage from "./views/HomePage.vue";
import AboutPage from "./views/AboutPage.vue";
import NotFound from "./views/NotFound.vue";

/** @type {import('vue-router').RouterOptions['routes']} */
export const routes = [
  { path: "/", component: HomePage, meta: { title: "HomePage" } },
  {
    path: "/about",
    meta: { title: "AboutPage" },
    component: AboutPage,
    // example of route level code-splitting
    // this generates a separate chunk (AboutPage.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import('./views/AboutPage.vue')
  },
  { path: "/:path(.*)", component: NotFound },
];
